import React, { useState } from "react"
import { connect, useSelector } from "react-redux"

import Layout from "../../components/layout"
import {
  WhiteLabelApplicationQuery,
  WhiteLabelApplicationFormQuery,
} from "../../prismic/staticQueries"
import {
  BreadCrumbBanner,
  SubscriptionPanel,
  AddFormFields,
} from "../../components/shared"
import { sendEmail } from "../../actions"
import { SUCCESS_MESSAGES } from "../../constants"
import { Form, Button } from "react-bootstrap"
const WhiteLabelApplication = () => {
  const language = useSelector(state => state.language)
  const { banner_image, page_heading } = WhiteLabelApplicationQuery(language)
  const whiteLabelApplicationForm = WhiteLabelApplicationFormQuery(language)

  const [formData, updateFormData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(null)

  const handleChange = e => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    })
  }

  const handleSubmit = async e => {
    setIsLoading(true)
    setMessage(null)
    e.preventDefault()
    try {
      await sendEmail("contact-us", formData)
      setMessage(SUCCESS_MESSAGES.EMAIL_SENT[language])
      updateFormData({})
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <Layout>
        <BreadCrumbBanner heading={page_heading} banner_image={banner_image} />

        <section className="apply-form p-top-100 p-bottom-110">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {message ? (
                  <div className="alert alert-success">{message}</div>
                ) : (
                  ""
                )}

                <Form
                  onSubmit={handleSubmit}
                  className="white-label-application"
                >
                  {whiteLabelApplicationForm.body.map((item, index) => (
                    <>
                      <h4 className="border-bottom pb-4 mb-5 mt-5">
                        {item.primary.group_title[0].text}
                      </h4>
                      <AddFormFields
                        data={item.items}
                        display={index === 0 ? "" : "block"}
                        row={index === 0}
                        onChange={handleChange}
                      />
                    </>
                  ))}

                  <div className="mt-5">
                    <Button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn-purple"
                      onclick="WhiteLabelSubmit();"
                    >
                      {whiteLabelApplicationForm.submit_button_text[0].text}
                    </Button>
                    <Button type="button" className="btn btn-secondary mx-3">
                      {whiteLabelApplicationForm.cancel_button_text[0].text}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(WhiteLabelApplication)
